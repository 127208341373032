import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';
import styles from './styles';

const { palette } = lightTheme;

export const getBaseStyles = (
  variant: 'blue' | 'white',
  isCardLink: boolean
) => {
  const backgroundStyle: Record<string, SxProps> = {
    blue: {
      backgroundColor: palette.primary['50']
    },
    white: {
      backgroundColor: palette.grey['50']
    }
  };

  return isCardLink
    ? {
        ...styles.baseState,
        ...backgroundStyle[variant]
      }
    : {
        ...styles.baseStateNoLink,
        ...backgroundStyle[variant]
      };
};

export function getLink(link: string | undefined) {
  if (link !== '' || typeof link !== 'undefined') {
    return link;
  }

  return undefined;
}

export const getCursorStyle = (href: string) =>
  href ? styles.cardActionAreaActive : styles.cardActionAreaNonActive;
