import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActionAreaProps,
  Box,
  Grid
} from '@mui/material';
import Link from 'next/link';
import CircleIcon from '@mui/icons-material/Circle';
import Markdown from 'markdown-to-jsx';
import { Button } from '../Button';
import markdownOverrides from '../../utils/MarkdownOverrides';
import { getIcon } from '../../utils/getIcon';
import { getBaseStyles, getCursorStyle } from './utility';
import styles from './styles';
import { bindComponentOnClickHandler } from '../../utils';

export interface LibCardLearnMoreProps extends CardActionAreaProps {
  icon?: string;
  richText?: string;
  title?: string;
  subtitle?: string;
  linkText?: string;
  href?: {
    hrefLink?: string;
    target?: string;
  };
  handleClick?: () => void;
  variant?: 'blue' | 'white';
  btnPrimary?: null | {
    label: string;
    href: string;
    colorProp?: 'primary' | 'secondary' | 'tertiary' | 'text';
    size?: 'large' | 'medium' | 'small';
    target?: string;
    isLink?: boolean;
  };
}

export function customBulletPoint({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={styles.bulletPoint}>
      <CircleIcon sx={styles.iconTick} />
      <Typography variant="body1" sx={styles.textStyles}>
        {children}
      </Typography>
    </Box>
  );
}

const overrides = {
  ...markdownOverrides,
  h1: {
    component: Typography,
    props: { variant: 'h2', sx: styles.title }
  },
  h2: {
    component: Typography,
    props: { variant: 'h2', sx: styles.title }
  },
  h3: {
    component: Typography,
    props: { variant: 'h3', sx: styles.centerHeading }
  },
  h4: {
    component: Typography,
    props: { variant: 'h4', sx: styles.centerHeading }
  },
  h5: {
    component: Typography,
    props: { variant: 'h5', sx: styles.centerHeading }
  },
  h6: {
    component: Typography,
    props: { variant: 'h6', sx: styles.centerHeading }
  },
  p: {
    component: Typography,
    props: { variant: 'body1', sx: styles.textStyles }
  },

  strong: {
    component: Typography,
    props: { variant: 'heavy', sx: styles.strong }
  },
  li: {
    component: customBulletPoint
  },
  ul: {
    component: Box
  }
};

const getClickHandler = (e, href: string, handleClick: (e) => void) => {
  if (href.indexOf('component:') > -1) {
    bindComponentOnClickHandler(href);
  }

  return handleClick?.(e);
};

function LibCardLearnMore({
  icon,
  richText,
  title,
  subtitle,
  linkText,
  href,
  handleClick,
  variant = 'blue',
  btnPrimary
}: LibCardLearnMoreProps) {
  const DynamicIcon = icon ? getIcon(icon) : null;

  const isCardALink = Boolean(
    btnPrimary?.label || linkText || href.hrefLink.indexOf('component:') > -1
  );

  return (
    <Card sx={getBaseStyles(variant, isCardALink)}>
      <Box
        component={
          btnPrimary?.label || !href.hrefLink || linkText ? 'div' : 'a'
        }
        href={isCardALink ? undefined : href.hrefLink}
        sx={getCursorStyle(
          btnPrimary?.label || !href.hrefLink || linkText ? null : href.hrefLink
        )}
        target={href.target}
        onClick={(e) =>
          btnPrimary?.label || linkText
            ? null
            : getClickHandler(e, href.hrefLink, handleClick)
        }
        disableRipple={Boolean(btnPrimary?.label || linkText)}
      >
        <Grid container sx={styles.mainContainer}>
          <Grid item sx={styles.gridRoot}>
            <CardContent sx={styles.cardContainer} className="Card-xx">
              <Box sx={styles.detailsContainer}>
                {typeof icon !== 'undefined' && (
                  <Box sx={styles.mainIcon}>
                    <React.Suspense fallback="">
                      <DynamicIcon />
                    </React.Suspense>
                  </Box>
                )}
                {richText && (
                  <Markdown options={{ overrides }}>{richText}</Markdown>
                )}
                {title ? (
                  <Typography variant="h2" sx={styles.title}>
                    {title}
                  </Typography>
                ) : null}
                {subtitle ? (
                  <Typography variant="body1" sx={styles.subtitle}>
                    {subtitle}
                  </Typography>
                ) : null}
                {linkText && !btnPrimary?.label ? (
                  <Box sx={styles.footerContainer}>
                    <Box sx={styles.footer}>
                      {href.hrefLink.indexOf('component:') > -1 ||
                      href.hrefLink === '' ? (
                        <Button
                          variant="tertiary"
                          onClick={(e) =>
                            getClickHandler(e, href.hrefLink, handleClick)
                          }
                        >
                          {linkText}
                        </Button>
                      ) : (
                        <Link
                          href={href?.hrefLink}
                          target={href.target || '_self'}
                        >
                          <Button variant="tertiary">{linkText}</Button>
                        </Link>
                      )}
                    </Box>
                  </Box>
                ) : null}
                {btnPrimary ? (
                  <Box sx={styles.footerContainer}>
                    {btnPrimary?.isLink ? (
                      <Box sx={styles.btnPrimaryLink}>
                        <Link
                          prefetch={false}
                          href={btnPrimary?.href || ''}
                          target={btnPrimary?.target || ''}
                        >
                          <Button
                            color="primary"
                            variant={btnPrimary?.colorProp}
                            size={btnPrimary?.size || 'medium'}
                            sx={styles.btnPrimary}
                          >
                            {btnPrimary?.label}
                          </Button>
                        </Link>
                      </Box>
                    ) : (
                      <Button
                        color="primary"
                        variant={btnPrimary?.colorProp}
                        size={btnPrimary?.size || 'medium'}
                        sx={styles.btnPrimary}
                      >
                        {btnPrimary?.label}
                      </Button>
                    )}
                  </Box>
                ) : null}
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export const CardLearnMore = React.memo(LibCardLearnMore);

/*
Notes: 
If btnPrimary is provided, linkText would not be rendered. To render, linkText, btnPrimary must be null
*/
