import React from 'react';

export const getIcon = (icon: string) => {
  const ImportedIcon = React.lazy(() =>
    import(`../icons/${icon}`).catch(() => import('../icons/Bug'))
  );

  return ImportedIcon;
};

export default getIcon;
