import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing, containers } = lightTheme;

const styles: Record<string, SxProps> = {
  blue: {
    backgroundColor: palette.grey['50']
  },
  white: {
    backgroundColor: palette.lightishAqua.main
  },

  container: containers.type2,

  // TODO: remove this section when sectionTitle is extracted to markdownOverrides
  sectionTitle: {
    color: palette.primary.main,
    marginBottom: spacing(1)
  }
};

export default styles;
