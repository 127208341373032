import { Box, Grid, Typography } from '@mui/material/';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import markdownOverrides from '../../utils/MarkdownOverrides';
import { CardLearnMore } from '../../components/CardLearnMore';

import styles from './styles';

const overrides = {
  ...markdownOverrides,
  h1: {
    component: Typography,
    props: { variant: 'h1', sx: styles.sectionTitle }
  },
  h2: {
    component: Typography,
    props: { variant: 'h2', sx: styles.sectionTitle }
  }
};

export interface LibLearnMoreProps {
  cardsContent: Array<{
    icon: string;
    richText?: string;
    title?: string;
    subtitle?: string;
    linkText: string;
    href: string;
    handleClickCard?: () => void;
    target?: string;
    variant?: 'blue' | 'white';
    id?: string;
    btnPrimary?: {
      label: string;
      href: string;
      colorProp?: 'primary' | 'secondary' | 'tertiary' | 'text';
      size?: 'large' | 'medium' | 'small';
      target?: string;
      isLink?: boolean;
    };
  }>;
  richTextTitle?: string;
  cardsPerRow?: number;
  variant?: 'blue' | 'white';
}

function LibLearnMore({
  cardsContent,
  richTextTitle,
  cardsPerRow = 3,
  variant = 'blue'
}: LibLearnMoreProps) {
  return (
    <Box sx={styles[variant]} component="section">
      <Box sx={styles.container}>
        {richTextTitle && (
          <Markdown options={{ overrides }}>{richTextTitle}</Markdown>
        )}
        <Grid container spacing={{ xs: '0.625rem', md: 1, lg: 1 }}>
          {cardsContent?.map((item) => (
            <Grid item xs={12} md={12 / cardsPerRow} key={item.id}>
              <CardLearnMore
                icon={item.icon}
                richText={item.richText}
                title={item.title}
                subtitle={item.subtitle}
                linkText={item.linkText}
                href={{ hrefLink: item.href, target: item.target }}
                handleClick={item?.handleClickCard || null}
                variant={variant}
                btnPrimary={item.btnPrimary?.label && item.btnPrimary}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export const LearnMore = React.memo(LibLearnMore);

export default LearnMore;
