import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, breakpoints, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  baseState: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
    transition: 'transform 0.5s',
    boxShadow: 'none',
    zIndex: '0',
    display: 'block',
    borderRadius: '0',
    '& > a': {
      textDecoration: 'none'
    },

    [breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: palette.primary['400'],
        zIndex: '1',

        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          border: `0.25rem solid ${palette.primary.main}`,
          backgroundColor: palette.primary.main
        }
      }
    }
  },

  // styles applied to card for no hover effects (in case card does not have href)
  baseStateNoLink: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
    transition: 'transform 0.5s',
    boxShadow: 'none',
    zIndex: '0',
    display: 'block',
    borderRadius: '0',
    '& > a': {
      textDecoration: 'none'
    },

    [breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: '#f2f8f7',
        zIndex: '1'
      }
    }
  },

  cardActionAreaActive: {
    height: '100%',
    cursor: 'pointer'
  },
  cardActionAreaNonActive: {
    height: '100%',
    cursor: 'default'
  },
  mainContainer: {
    height: '100%'
  },
  gridRoot: {
    width: '100%'
  },
  cardContainer: {
    minHeight: {
      xs: '100%',
      md: '19rem'
    },
    height: '100%',
    // NOTE: Need to use !important tag to override Mui-last-child class

    '&.MuiCardContent-root': {
      padding: {
        xs: spacing(1),
        md: `${spacing(2)} ${spacing(1)}`,
        lg: spacing(2)
      }
    }
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%'
  },
  mainIcon: {
    height: { xs: '3.5rem', md: '4.5rem' },
    display: {
      xs: 'none',
      sm: 'block'
    },
    '& svg': {
      fontSize: '3rem',
      fill: 'none'
    }
  },
  title: {
    color: palette.primary.main,
    textTransform: 'none',
    marginTop: { xs: 0, sm: spacing(1) },
    marginBottom: spacing(1),
    width: '100%'
  },
  subtitle: {
    color: palette.grey.main,
    width: '100%',
    marginTop: '0.5rem',
    marginBottom: '1rem'
  },
  footerContainer: {
    marginTop: 'auto',
    marginLeft: '-0.5rem'
  },
  footer: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    button: {
      textTransform: 'none'
    },
    a: {
      textDecoration: 'none',
      textTransform: 'none',
      button: {
        textTransform: 'none'
      }
    }
  },
  footerIcon: {
    '& svg': {
      fill: palette.primary.main
    }
  },
  linkTitle: {
    fontSize: '1rem',
    fontWeight: '800',
    lineHeight: '1.25rem',
    color: palette.grey.main,
    textTransform: 'none',
    marginBottom: '0'
  },
  iconTick: {
    fontSize: '0.5rem'
  },
  bulletPoint: {
    display: 'flex',
    alignItems: 'baseline',
    gap: spacing(0.5),
    color: palette.grey.main,
    span: {
      marginLeft: spacing(0.5)
    }
  },
  textStyles: {
    color: palette.grey.main,
    marginBottom: spacing(0.5)
  },
  btnPrimaryLink: {
    a: {
      textDecoration: 'none',
      width: '100%'
    }
  },
  btnPrimary: {
    width: '100%'
  }
};

export default styles;

// NOTE: Last title and parapgaph changes made due to FDW-1870
